import React from "react"
import style from "./Hero.module.scss"
import { Row, Col } from "react-flexbox-grid"
import Calculator from "./Calculator"

export default () => (
  <div className={style.estimateCosts}>
    <div className={style.container}>
      <Row>
        <Col sm={12} md={6}>
          <h2>Estimate your costs</h2>
          <p>
            Do you want to know exactly how much you are going to spend? Use our
            calculator to estimate your monthly cost.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={`${style.middleXs} ${style.showXs}`}>
          <a
            className="uh-button uh-button-outline uh-button-lg full-width"
            href="/calculator"
            target="_blank"
          >
            Open Calculator
          </a>
        </Col>
        <Col md={12} className={style.hideXs}>
          <Calculator />
        </Col>
      </Row>
      <link rel="stylesheet" href="/css/calculator.css" />
      <script type="text/javascript" src="/js/calculator.js"></script>
    </div>
  </div>
)
