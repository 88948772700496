import React from "react"
import style from "./Hero.module.scss"

export default () => (
  <section
    className={`${style.section} ${style.estimateSection}`}
    id="estimate"
  >
    <div className={style.estimateHeaderImg}></div>
    <div className={style.container}></div>
  </section>
)
